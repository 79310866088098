
import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar,Tabs,Tab, Toolbar,useMediaQuery, useTheme, CardMedia} from "@mui/material"
import DrawerComp from "../components/Drawer";
import Image from '../images/logo_sakoo.jpg';
import "../App.css";

import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    padding: 0
  },
  img: {
    backgroundImage: `url(${Image})`,
    width: '100px',
    height: "100px",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
 
}));

function Navbar() {
  const [value, setValue] = useState(false);
  const classes = useStyles();

  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  function handleChange(event, Value) {
    setValue(Value);
  }
  
  return (
    <div className="Header">
     <AppBar sx={{ background: "#f8f9fa", color: "#03071e" }} >
        <Toolbar>
        <CardMedia className={classes.img} variant="outlined" component='div' />
  
          {isMatch ? (
            <>
             
              <DrawerComp />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "35%", background: "#f8f9fa", color: "#03071e" }}
                indicatorColor="primary"
                textColor="secondary"
                value={value}
                onChange={handleChange} 
               
              >
              <Link to="/" className='underline'> <Tab label="HEM"  value="true"/> </Link> 
              <Link to="/profil" className='underline'><Tab  label="PROFIL" value="true" />  </Link> 
              <Link to="/services" className='underline'> <Tab label="TJÄNSTER" value="true" />   </Link>  
              <Link to="/contact" className='underline'> <Tab label="KONTAKTA OSS" value="true"/></Link>
              </Tabs>
             
            </>
          )}
        </Toolbar>
      </AppBar>
      </div>
  );
}

export default Navbar;