
import "../App.css";
import Header from "../components/Header";
import Prices from "../components/Prices"


function Home() {
  return (
    <div className="home" id="home">
    <Header />
    <Prices/>
    
    </div>
  )
 
}

export default Home;
