import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

function Prices() {
  const useStyles = makeStyles((theme) => ({
    blogsContainer: {
      paddingTop: theme.spacing(3),
    },
    blogTitle: {
      fontWeight: 800,
      paddingBottom: theme.spacing(3),
    },
    card: {
      maxWidth: "100%",
    },
    media: {
      height: 240,
    },
    cardActions: {
      display: "flex",
      margin: "0 10px",
      justifyContent: "space-between",
    },
    author: {
      display: "flex",
    },
  }));

  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      marginTop: "30",
      backgroundColor: "#3e5264",
    },
  };

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      secondary: {
        // This is green.A700 as hex.
        main: "#3e5264",
      },
    },
  });

  return (
    <div>
      <Container
        maxWidth="lg"
        className={classes.blogsContainer}
        sx={{ mb: 5 }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5" className={classes.blogTitle}>
            PRISER
          </Typography>
        </Box>

        <Grid container spacing={3} align="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/1.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />

                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    SMALL
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    1200 sek/månad
                  </Typography>
                  <Typography>0-25 verifikationer Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/2.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    MEDIUM
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    1900 sek/månad
                  </Typography>
                  <Typography>26-50 verifikationer Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/3.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    LARGE
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    2700 sek/månad
                  </Typography>
                  <Typography>51-75 verifikationer Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/4.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    ANPASSAD
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    offert
                  </Typography>
                  <Typography>kontakta oss Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/5.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    LÖPANDE
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    offert
                  </Typography>
                  <Typography>kontakta oss Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../images/6.png")} // require image
                  title="Contemplative Reptile"
                  style={styles.media}
                  component="src" // specify styles
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                    EXTRA LARGE
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    3300 sek/månad
                  </Typography>
                  <Typography>76-100 verifikationer Fastpris</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Box textAlign="center" sx={{ m: 2, mt: 8, mb: 8 }}>
          <ThemeProvider theme={theme}>
            <Link to="/services" className="underline">
              <Button variant="contained" color="secondary">
                {" "}
                SE ALLA TJÄNSTER
              </Button>
            </Link>
          </ThemeProvider>
        </Box>

        <Box>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} sm={6} md={6}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../images/7.png")} // require image
                    title="Contemplative Reptile"
                    style={styles.media}
                    component="src" // specify styles
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h5">
                      23, December 2021
                    </Typography>
                    <Typography>
                      Nya lagar om skatter och folkbokföring 2022 Höjt
                      grundavdrag för personer över 65 år. Höjt jobbskatteavdrag
                      för låg- och medelinkomsttagare. Utökad skattereduktion
                      för gåvor. Det är tre skatteförändringar som träder i
                      kraft vid årsskiftet.
                    </Typography>
                    <Typography>
                      Fler skattenyheter berör både privatpersoner och företag
                      från den 1 januari och även flera lagändringar på
                      folkbokföringsområdet.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../images/8.png")} // require image
                    title="Contemplative Reptile"
                    style={styles.media}
                    component="src" // specify styles
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h5">
                      10, Januari 2022
                    </Typography>
                    <Typography>
                      Exempellistan över godkända friskvårdsaktiviteter som
                      tidigare fanns publicerad på skatteverket.se har tagits
                      bort. Det beror på att listan gick att uppfatta som en
                      komplett lista över godkända aktiviteter, vilket den inte
                      var.
                    </Typography>
                    <Typography>
                      Precis som tidigare är det innehållet som avgör om en
                      aktivitet skattefritt kan bekostas med friskvårdsbidrag.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Prices;
