import emailjs from '@emailjs/browser'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';


const SERVICE_ID= process.env.REACT_APP_SERVICEID
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID
const USER_ID = process.env.REACT_APP_USERID

const Contacts = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        alert("Message was sent successfully");
      }, (error) => {
        console.log(error.text);
        alert("Error occured");
      });
     
    e.target.reset()
  };

  return (
    <Form onSubmit={handleOnSubmit} >
      <Form.Group className="form_control1" >
     
        <Form.Control className="rounded_text  mb-3" type="name" id="name" name="from_name" required placeholder="Namn"  />
        
        <Form.Control
          className="rounded_text mb-3"
          type="email"
          id="email"
          name="user_email"
          required
          placeholder="namn@example.com"
          
          
          
        />
      </Form.Group>
      <Form.Group className="mb-3 ">
        <Form.Control
          className="rounded_text  mb-3"
          as="textarea"
          type="message"
          rows={6}
          cols={20}
          id="message"
          name="message"
          required
          placeholder="Meddelande "

          
        />
        <div className="text-left">
          <Button variant="secondary" type="submit" className="rounded">
            Skicka
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
};

export default Contacts;
