import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Profil from "./pages/Profil";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";



function App() {
  return (
    <>
     <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/profil" element={<Profil />} />
           <Route path="/services" element={<Services />} />
           <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>
   
    </>
  );
 
}

export default App;
