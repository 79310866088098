import React from 'react'
import {Box,Typography,Container,Grid,Card,Button, CardContent, Divider} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Image from '../images/33.png';
import Image2 from '../images/11.png';

function ServicesProfil() {
    const useStyles = makeStyles((theme) => ({
      menuColor: {
       
        color: '#ffffff',
      },
      subColor: {
          color: '#000000'
      },
        blogsContainer: {
          paddingTop: theme.spacing(3),
     
        },
        blogTitle: {
          fontWeight: 800,
          paddingBottom: theme.spacing(3)
        },
        card: {
          maxWidth: "100%",
          height: 300,
          backgroundImage:  `url(${Image})`,
          backgroundSize: 'cover',
          backgroundColor:"#3B4A59",
          marginBottom: 8,
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '30px'
         
        },
        card2: {
          maxWidth: "100%",
          height: 300,
          backgroundImage:  `url(${Image2})`,
          backgroundSize: 'cover',
          backgroundColor:"#fefae0",
          marginBottom: 8,
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '30px'
        },
    
      }));
      const devide = {
        media: {
          width: '50%',
          margin: 'auto' ,
          marginBottom: 50,
          backgroundColor:"#fefae0"
        }
    };
      const styles = {
        media: {
          
          backgroundColor:"#4E7A83"
        }
    };
    const styles2 = {
      media: {
       
        backgroundColor:"#0C2135"
      }
  };
      
    const classes = useStyles();
    
;

  return (
    <div>
    <Container maxWidth="lg" className={classes.blogsContainer}  sx={{ mb: 5 }}>
        <Box  display="flex"
  justifyContent="center"
  alignItems="center"
  >
       
        </Box>
        <Typography align="center" variant="h6" component="div"
      sx={{ mx:6, mb:8,
        fontSize: {
         lg: 30,
         md: 25,
         sm: 20,},
         marginTop: {
           xs: 0,
           sm: 0,
           md: 6,
         }
         }} >
                 AGLO REDOVISNING
                  </Typography>
                  
        <Grid container spacing={3} align="left" >
        
          <Grid item xs={12} sm={6} md={6}>
           <Box >
                  
                  <Typography variant="body1" gutterBottom >
                  Vår verksamhet bygger på att underlätta vardagen för dig som företagare. Fokusera på verksamheten – vi tar hand om bokföringen.
                  </Typography>
                 <Typography variant="body1" gutterBottom >
                  Vi hjälper dig med löpande bokföring, lönehantering, momsredovisning, företagsanalys, bokslut,
                   årsredovisning och deklaration så att du kan fokusera på försäljningen.
                   </Typography>
                  <Typography variant="body1" gutterBottom >Vi kan även sköta din fakturering och dina redovisningar till skatteverket och vi kan 
                  sköta leverantörsreskontran. Detta medan du gör det du är bäst på, sköta din verksamhet.</Typography>
                  <Typography variant="body1" gutterBottom >Deklarationsombud för dig som kund</Typography>
                  <Typography variant="body1" gutterBottom >För att kunna företräda dig som kund och därmed lämna in din färdiga inkomstdeklaration behöver 
                  vi tillåtelse av dig att agera ombud.</Typography>
                  <Typography variant="body1" gutterBottom >Om du inte redan har gett din rådgivare behörighet hos Skatteverket som deklarationsombud så
                   kontakta din rådgivare för att lösa detta.</Typography>
                  <Typography variant="body1" gutterBottom >Digital & personlig redovisning</Typography>
                  <Typography variant="body1" gutterBottom >Hos AGLOREDOVISNING får du alltid en dedikerad redovisningskonsult som ger dig personlig service 
                  i kombination med smarta tekniska lösningar.</Typography>
                  <Typography variant="body1" gutterBottom >På så sätt behöver du inte välja mellan en digital tjänst och personlig kontakt; hos oss får du 
                  båda delarna.

I vår löpande tjänst får du inte bara rätt siffra deklarerat till Skatteverket på rätt dag utan även en kunnig redovisningskonsult
 att bolla frågor med.</Typography>
                  <Typography variant="body1" gutterBottom >Oavsett hur just du ser på ditt företags redovisning är vi säkra på att ha en lösning för dig. 
                  Självklart helt digitalt, med flexibla system som passar just dig och med hög nivå av personlig service till
                   lågt pris.</Typography>
                  
                   </Box>      
               
          </Grid>
         
          <Grid item xs={12} sm={6} md={6} >
            <Card className={classes.card2} style={styles.media} >
                <CardContent >
                  <Typography gutterBottom variant="h6" component="h5" className={classes.menuColor}  sx={{ mb: 5, mt: 5 }} >
                  HUR KAN VI HJÄLPA DIG
                  </Typography>
                  <Divider variant="inset" style={devide.media} />
                  <Link to="/contact" className='underline'>        
                  <Button  style={{
        borderRadius: 5,
        backgroundColor: "#f1faee",
        color: "#000000",
        padding: "8px 6px",
        width: 200,
        fontSize: "18px"
    }}
    variant="contained" align="center">
                  KONTAKTA OSS
                  </Button>
                  </Link>  
                </CardContent>
            </Card>


            <Card className={classes.card} style={styles2.media}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5" className={classes.menuColor}  sx={{ mb: 5, mt: 5 }}>
                  HUR KAN VI HJÄLPA DIG
                  </Typography>
                  <Divider variant="inset" style={devide.media} />
                  <Link to="/contact" className='underline'>
       
        <Button style={{
        borderRadius: 5,
        backgroundColor: "#f1faee",
        color: "#000000",
        padding: "8px 6px",
        width: 200,
        fontSize: "18px"
    }}
    variant="contained" align="center">
                  KONTAKTA OSS
                  </Button>
        
</Link>
                </CardContent>
            </Card>
          </Grid>
          
          </Grid>


</Container>
    </div>
  )
}

export default ServicesProfil