import React from 'react'
import HeaderProfil from "../components/HeaderProfil";
import ServicesProfil from '../components/ServicesProfil';



function Profil() {
  return (
    <div className='profil' id='profil' >
        <HeaderProfil />
        <ServicesProfil/>
    </div>
  )
}

export default Profil;
