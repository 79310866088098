import React from 'react'
import HeaderServices from '../components/HeaderServices'
import ServicesCards from '../components/ServicesCards'

function Services() {
  return (
    <div  className='services' id='services'>
        <HeaderServices/>
        <ServicesCards/>
    </div>
  )
}

export default Services