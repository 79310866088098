import React from 'react'
import {Box,Typography,Container,Grid,Card, CardContent} from "@mui/material"
import { makeStyles } from '@material-ui/core/styles';




function ServicesCards() {

  const useStyles = makeStyles((theme) => ({
    
        
    blogsContainer: {
      paddingTop: theme.spacing(3),
    },
    blogTitle: {
      fontWeight: 800,
      paddingBottom: theme.spacing(3)
    },
    card: {
      maxWidth: "100%",
      display: 'block',
      // width: '30vw',
      transitionDuration: '0.3s',
      height: '300px',
      
    },
  
  }));

  const styles = {
    firstCard : {
      backgroundColor:"#4E7A83",
      color: "#F9FCFD",
    },
    secondCard : {
      backgroundColor:"#769da9",
      color: "#F9FCFD",
    },
    thirdCard : {
      backgroundColor:"#769da9",
      color: "#F9FCFD",
    },
    fourthCard : {
      backgroundColor:"#4E7A83",
      color: "#F9FCFD",
    },
    textCard : {
    color: "#F9FCFD",
    
   }
};


  const classes = useStyles();
  
  return (
    <div>
       <Container maxWidth="lg" className={classes.blogsContainer}  sx={{ mb: 5 }}>
       <Box>
       <Typography align="center" variant="h6" component="div"
      sx={{  mb:8,
        fontSize: {
         lg: 30,
         md: 25,
         sm: 20,},
         marginTop: {
           xs: 0,
           sm: 0,
           md: 6,
         }
         }} >
                 TJÄNSTER
                  </Typography>

        <Grid container spacing={3} align="center">

          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.card} style={styles.firstCard}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                  BOKSLUT
                  </Typography>
                  <Typography variant="body1" color="textSecondary" component="p" style={styles.textCard}>
                  Vi levererar kompletta bokslut, årsbokslut och årsredovisningar med full dokumentation för Dig med 
                  enskild firma, handelsbolag eller aktiebolag. Vi säkerställer kvalitén av uppgifterna i Ert bokslut
                   och levererar alltid årsbokslut och årsredovisningar med högsta kvalitet.
                  </Typography>
                </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.card} style={styles.secondCard}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                  BOKFÖRING
                  </Typography>
                  <Typography variant="body1" color="textSecondary" component="p" style={styles.textCard}>
                  För oss är det viktigt att att Du lyckas med Ditt företag. Vid ett första möte ser
                   vi till att skräddarsy lösningar för just Dina behov. Vi arbetar med några av marknadens mest kompletta lösningar på nätet.
                  </Typography>
                </CardContent>
            </Card>
          </Grid>



        
          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.card} style={styles.thirdCard}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                  LÖNEADMINISTRATION
                  </Typography>
                  <Typography variant="body1" color="textSecondary" component="p" style={styles.textCard}>
                  Våra lönekonsulter säkerställer att lönerna går ut i tid till era anställda.
                  </Typography>
                </CardContent>
            </Card>
          </Grid>


        
          <Grid item xs={12} sm={6} md={6}>
            <Card className={classes.card} style={styles.fourthCard}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h5">
                  RÅDGIVNING
                  </Typography>
                  <Typography variant="body1" color="textSecondary" component="p" style={styles.textCard}>
                  Den person som sköter ett bolags bokföring får ofta en mycket god insyn i företagets ekonomi.
                  </Typography>
                </CardContent>
            </Card>
          </Grid>


          </Grid>
          </Box>
          </Container>
    </div>
  )
}

export default ServicesCards