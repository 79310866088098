import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box,Typography,useMediaQuery, useTheme, Button} from "@mui/material";
import { Link } from "react-router-dom";
import Image from '../images/sakoo.png';
import "../App.css";



const useStyles = makeStyles((theme) => ({
 
  hero: {
    backgroundImage: `url(${Image})`,
    height: "500px",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#f1faee",
   
    [theme.breakpoints.down("sm")]: {
      height: '200',
      fontSize: "3em",
      backgroundSize: "auto",
      backgroundPosition: "center",

    }
  },
 
}));


function HeaderServices() {
  const classes = useStyles();

 
  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);


  return (
    <div className="Header">

      <Box className={classes.hero}>
     
        <Box  sx={{ marginRight: "30%", paddingTop: "10%" }}>

        <Typography className='hero-text' variant="body1" component="p"
        sx={{ pl: 6,
        fontSize: {
         lg: 40,
         md: 35,
         sm: 25,  }
        }}>
        
         En digital redovisningsbyrå.
         </Typography>

        <Typography className='hero-text' variant="h5" component="p"
        sx={{ pl: 6,
            textTransform: 'uppercase',
        fontSize: {
         lg: 50,
         md: 40,
         sm: 35,    
    }
        }}>
       Anpassad för dig som företagare.
        </Typography>
        <Link to="/contact" className='underline'>
        <Box sx={{ marginLeft: "20%" }}>
             <Button style={{
        borderRadius: 5,
        backgroundColor: "#fefae0",
        color: "#000000",
        padding: "5px 2px",
        width: 200,
        fontSize: "14px"
    }}
    variant="contained" align="center">KONTAKTA OSS</Button>
        </Box> 
</Link>
     </Box>
      </Box>
   
    </div>
  );
}

export default HeaderServices;