import React, { useState } from "react";
import {Drawer,IconButton,List,ListItemButton,ListItemIcon,ListItemText} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";


const pages = [
  {
    id: 1,
    title: "HEM",
    path: "./",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 2,
    title: "PROFIL",
    path: "./profil",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 3,
    title:  "TJÄNSTER",
    path: "./services",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 4,
    title: "KONTAKTA OSS",
    path: "./contact",
    nName: "nav-item",
    sName: "sidebar-item",
  },
];


const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);


  return (
    <React.Fragment>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {pages.map((item, id) => (
            <ListItemButton key={item.id}>
              <ListItemIcon >
              <Link to={item.path} className='underline'>
                <ListItemText >{item.icon}
                    <span >{item.title}</span></ListItemText>
               </Link>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
