import React from 'react'
import {Box,Typography,Container,Grid,CardMedia } from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import "../App.css";
import { makeStyles } from '@material-ui/core/styles';
import Image from '../images/logo_sakoo.jpg';
import { BsInstagram,BsFacebook } from 'react-icons/bs';



function Footer() {

  const useStyles = makeStyles((theme) => ({
    
        
    blogsContainer: {
      paddingTop: theme.spacing(3),
      paddingBottom: '2rem',
    },
     img: {
      backgroundImage: `url(${Image})`,
      width: '200px',
      height: "200px",
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10%",
    }
  
  }));
  
  const styles = {
    contacts: {
      paddingTop: 10, // 16:9,
      marginTop:'20',
      backgroundColor:"#3e5264",
      color : "#f1faee",
    }
};
  
const classes = useStyles();


  return (
    <div className='footer'>
       
<Container className={classes.blogsContainer}>
    <Grid container spacing={3} align="center">
  <Grid item xs={12} sm={4} md={4}>
  
  <Box> <CardMedia className={classes.img} variant="outlined" component='div'/></Box>
  </Grid>

  <Grid item xs={12} sm={4} md={4}>

 
 <Box >   
      <Typography gutterBottom variant="h6" align="left" style={styles.contacts}> <LocalPhoneOutlinedIcon/> 0762413558
      </Typography>
      <Typography gutterBottom variant="h6" align="left" style={styles.contacts}><EmailOutlinedIcon/> info@agloredovisning.se
      </Typography>
      <Typography gutterBottom variant="h6" align="left" style={styles.contacts}><LocationOnOutlinedIcon/> AGLOREDOVISNING , Tallstigen 6, 710 41 Fellingsbro
      </Typography>
 </Box>
   
  </Grid>

  <Grid item xs={12} sm={4} md={4}>
  <a href="https://www.instagram.com/agloredovisning/" target="_blank" rel="noreferrer" > <Box > <BsInstagram className="icons"/></Box> </a>
  <a href="https://www.facebook.com/agloredovisning/" target="_blank" rel="noreferrer" ><Box ><BsFacebook className="icons"/></Box> </a>

 
 
</Grid>

    </Grid>
</Container>
       
    </div>
  )
}

export default Footer