import React from 'react'
import HeadreContacts from '../components/HeadreContacts';
import {Box,Typography,Container,Grid, Card } from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import "../App.css";
import { makeStyles } from '@material-ui/core/styles';
import Contacts from "../components/ContactUsForm";




// const SERVICE_ID= process.env.REACT_APP_SERVICEID;
// const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
// const USER_ID = "user_xU4tIdku8TqnIPsGXKD3r";


const Contact = () => {


  const useStyles = makeStyles((theme) => ({
    
        
    blogsContainer: {
      paddingTop: theme.spacing(8),
      paddingBottom: '4rem',
      margin: 'auto',
    },
  
  }));
  
  const styles = {
    contacts: {
      paddingTop: 20, // 16:9,
      marginTop:'30',
      backgroundColor:"none",
      color : "#3e5264",
    }
};
  
const classes = useStyles();

  return (
    <div className='conacts-container' id="contacts" >
        <HeadreContacts/>

    <div>
        <Container className={classes.blogsContainer}>
    <Grid container spacing={3} align="center">
  <Grid item xs={12} sm={6} md={4}>
  <Box>

      <Typography gutterBottom variant="h5" style={styles.contacts}>
      KONTAKTA OSS</Typography>
 </Box>
 <Box >    
  
      <Typography gutterBottom variant="h6" align="left" style={styles.contacts}> <LocalPhoneOutlinedIcon/> 0762413558
      </Typography>

      <Typography gutterBottom variant="h6" align="left" style={styles.contacts}><LocationOnOutlinedIcon/> AGLOREDOVISNING, Tallstigen 6, 710 41 Fellingsbro
      </Typography>
 </Box>
  </Grid>

  <Grid item xs={12} sm={6} md={4}>

  <Card style={{ maxWidth: 450, padding: "20px 5px", margin: " auto" }}>
        
            
            <Container >
            <Contacts/>
            
            {/* <Contacts lg={3} class="text-lg-center pb-5 pt-5 " /> */}
              {/* <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField 
                  placeholder="Enter  name" 
                  label=" Name" 
                  variant="outlined" 
                  name="from_name" 
                  fullwidth 
                  required />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                  type="email" 
                  placeholder="Enter email" 
                  label="Email" 
                  variant="outlined"
                  name="user_email" 
                  fullwidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                  type="number" 
                  placeholder="Enter phone number" 
                  label="Phone" 
                  variant="outlined" 
                  name="number"
                  fullwidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                  label="Message" 
                  multiline rows={4} 
                  placeholder="Type your message here"
                  name="message" 
                  variant="outlined" 
                  fullwidth required />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained"  color="primary" >Submit</Button>
                </Grid>

              </Grid> */}
            </Container>
         
        </Card>
  </Grid>

  <Grid item xs={12} sm={6} md={4} style={{ margin: 'auto' }}>

  <iframe title="address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.3170446841486!2d15.595159416076248!3d59.427786681693355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465c2525474ac061%3A0x325f374e8bc91812!2sTallstigen%206%2C%20710%2041%20Fellingsbro!5e0!3m2!1sen!2sse!4v1652207499866!5m2!1sen!2sse"  width="auto" height="440" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
</Grid>

    </Grid>
</Container>
</div>
    </div>
  )
}

export default Contact;
