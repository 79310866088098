import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Image from "../images/sakoo.png";
import "../App.css";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${Image})`,
    height: "500px",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#f1faee",

    [theme.breakpoints.down("sm")]: {
      height: "200",
      fontSize: "3em",
      backgroundSize: "auto",
      backgroundPosition: "center",
    },
  },

  text: {
    width: "80%",
    margin: "auto",
  },
}));

function Header() {
  const classes = useStyles();

  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  return (
    <div className="Header">
      <Box className={classes.hero}>
        <Box
          className="hero-text"
          sx={{ marginRight: "30%", paddingTop: "10%" }}
        >
          <Typography
            className={classes.font}
            variant="body1"
            component="p"
            sx={{
              pl: 6,
              fontSize: {
                lg: 40,
                md: 35,
                sm: 25,
              },
            }}
          >
            En digital redovisningsbyrå.
          </Typography>
          <Typography
            className={classes.font}
            variant="h5"
            component="p"
            sx={{
              pl: 6,
              fontSize: {
                lg: 50,
                md: 40,
                sm: 35,
              },
            }}
          >
            EKONOMITJÄNSTEN FÖR SMÅFÖRETAGARE
          </Typography>

          <Link to="/services" className="underline">
            <Box sx={{ marginLeft: "20%" }}>
              <Button
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fefae0",
                  color: "#000000",
                  padding: "5px 2px",
                  width: 200,
                  fontSize: "14px",
                }}
                variant="contained"
                align="center"
              >
                SE TJÄNSTER
              </Button>
            </Box>{" "}
          </Link>
        </Box>
      </Box>
      <Box>
        <Typography
          align="center"
          variant="h6"
          component="div"
          mt="1"
          sx={{
            mx: 6,
            mb: 8,
            fontSize: {
              lg: 30,
              md: 25,
              sm: 20,
            },
            marginTop: {
              xs: 0,
              sm: 0,
              md: 8,
            },
          }}
        >
          VÄLKOMMEN TILL AGLO REDOVISNING
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 8,
          mx: "auto",
          mb: 8,
          width: {
            lg: "60%",
            md: "80%",
            sm: "100%",
          },
        }}
      >
        <List sx={{ mx: 2, p: 0 }}>
          <Typography className="font">
            Vi har inga dyra lokaler! Alla fakturor och kvitton ni kan skicka i
            ett brev till oss.
          </Typography>

          <Typography>
            Vi är ett litet företag med små omkostnader och vi förstår dig som
            småföretagare, vår filosofi är inte att ha dyra lokaler och möbler,
            marmorgolv och andra onödiga saker som i slutänden kunderna får
            betala.
          </Typography>

          <Typography>
            Vi vill tvärtom vara den lilla prisvärda byrån!
          </Typography>

          <Typography>
            Vi är en lokal redovisningsbyrå i Stockholm och Örebro som arbetar
            med löpande redovisning, delårsrapporter, deklarationer och
            årsbokslut.
          </Typography>
        </List>
      </Box>
    </div>
  );
}

export default Header;
